// i18next-extract-mark-ns-start capabilities-emissions-aftertreatment

import { LINKS, EMISSIONS_NAV, CAPABILITIES_PRODUCTS_REALTIME_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import ResourceListing from '@components/views/ResourceListing';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilitiesEmissionsAftertreatmentPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const icon1 = getImage(data.icon1.childImageSharp.gatsbyImageData);
	const icon2 = getImage(data.icon2.childImageSharp.gatsbyImageData);
	const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							The simulation toolset provides the capability to predict exhaust emissions using 1D simulation, allowing the rapid analysis of different component options.
							This includes analysing complex models of catalyst honeycombs with surface chemical reactions, defining exhaust boundary conditions and mapping
							heat transfer models in the system.
							Coupled with a powertrain library, the exhaust system performance can be predicted across a complete drive cycle with analysis of exhaust materials and
							the thermal operating conditions over the life of the catalyst.
						</p>
						<ul>
							<li>Allows investigation of exhaust systems without specific catalytic converter experience</li>

							<li>Building blocks including catalytic converter systems with fluid channel and wall heat transfer models</li>

							<li>Simulates the balance of vehicle performance with fuel economy and lower emissions</li>

							<li>Fast model setup and rapid simulation times reducing the need for physical prototype builds</li>
						</ul>
					</Trans>

					{image && (
						<p>
							<GatsbyImage image={image} alt="Emissions aftertreatment modelling" />
						</p>
					)}

					<Trans i18nKey="content_2">
						<p>
							Exhaust analysis consists of two packages coupling emissions data from combustion with aftertreatment systems modelling:
						</p>
					</Trans>

					{icon1 && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={icon1}
								alt="IGNITE"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_3">
						<p>
							IGNITE, is a complete physics-based aftertreatment simulation package.
							With a library system of building blocks, the user can quickly and accurately model complete exhaust systems, analysing data around emissions,
							performance and efficiency.
						</p>
					</Trans>

					{icon2 && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={icon2}
								alt="WAVE-RT"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_4">
						<p>
							WAVE-RT, is a 1D fluid dynamics tool that provides engine performance and emissions data to the aftertreatment system model.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_CuttingEmissions")}
						className="widget__sibling__pages">
						<AssetList>
							{EMISSIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_PRODUCTS_REALTIME_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default CapabilitiesEmissionsAftertreatmentPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-emissions-aftertreatment", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/emissions/Realis-Simulation_1325_motorcycleExhaust_cropped.jpg" }) {
		...imageBreaker
	}
	icon1: file(relativePath: { eq: "content/logos/IGNITE.png" }) {
		...imageBreaker
	}
	icon2: file(relativePath: { eq: "content/products/wave/rs_wavert.png" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/markets/motorcycle/Realis-Simulation_994_motorcycleAftertreatment.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { tags: { in: "Exhaust aftertreatment" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 9
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
